<template>
  <v-footer
    id="home-footer"
    color="grey darken-4"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          class=""
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-start justify-md-none">
            <template v-for="(s, i) in links">
              <a
                :key="s.label"
                class="white--text pa-1 pa-md-0"
                :href="s.url"
                v-text="s.label"
              />

              <v-responsive
                v-if="i < links.length - 1"
                :key="`divider-${s.label}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="text-md-right"
        >
          <div class="d-flex flex-wrap justify-end justify-md-none">
            <template v-for="(s, i) in social">
              <a
                :key="s"
                class="white--text pa-1 pa-md-0"
                href="#"
                v-text="s"
              />

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="text-center"
          cols="12"
          md="12"
        >
          © {{ new Date().getFullYear() }} — ScrumOnDemand by
          <a
            href="https://elyoninternational.com/"
            target="_blank"
            rel="noreferrer"
          >
            Elyon Innovations LLC
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      links: [
        { label: 'About Us', url: '#/about' },
        { label: 'Communities', url: '#/communities' },
        { label: 'Launch', url: '#/news/scrum-on-demand-launch' },
        { label: 'Privacy Policy', url: '#/privacypolicy' },
      ],
      social: [
        'Facebook',
        'Twitter',
        'Instagram',
        'Linkedin',
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
